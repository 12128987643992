import React from 'react';
import DigitalCarousel1 from "../carousels/DigitalCarousel1";
import DigitalCarousel2 from "../carousels/DigitalCarousel2";
import DigitalCarousel3 from "../carousels/DigitalCarousel3";
import DigitalCarousel4 from "../carousels/DigitalCarousel4";
import DigitalCarousel5 from "../carousels/DigitalCarousel5";
import DigitalCarousel6 from "../carousels/DigitalCarousel6";

function DigitalContent(props) {
    return (
        <div className='flex flex-col'>
            <img
                src="https://srv-cdn.onedio.com/store/9a7adfb53602d4a471332493c6f6fa7c4408ea9dc23dc2dd7830ec51cd240b13.jpeg"
                className="w-full max-w-[1440px] mx-auto" alt="Onedio Header Image"/>

            {/*LIST*/}
            <div className="flex flex-col gap-5 px-1 lg:px-10 lg:py-16 py-6 max-w-[1440px] mx-auto mt-5" id="datalar">
                <div className="text-3xl lg:text-5xl font-semibold text-black">Datalar</div>
                <div className="flex items-center flex-col lg:flex-row gap-5 h-auto">
                    <ul className="flex lg:flex-col gap-3 nav-v-pill-wrapper absolute lg:relative w-full lg:w-auto overflow-x-auto">
                        <li className="h-10 lg:h-16 lg:w-[460px] border whitespace-nowrap w-full flex items-center px-4 lg:pl-4 text-md active font-semibold">Onedio
                            Analytics Verileri
                        </li>
                        <li className="h-10 lg:h-16 lg:w-[460px] border whitespace-nowrap w-full flex items-center px-4 lg:pl-4 text-md ">Cinsiyet</li>
                        <li className="h-10 lg:h-16 lg:w-[460px] border whitespace-nowrap w-full flex items-center px-4 lg:pl-4 text-md ">Yaş
                            Kırılımı
                        </li>
                        <li className="h-10 lg:h-16 lg:w-[460px] border whitespace-nowrap w-full flex items-center px-4 lg:pl-4 text-md ">Sosyal
                            Medya
                        </li>
                    </ul>
                    <div className="mt-24 lg:mt-0"><img
                        src="https://srv-cdn.onedio.com/store/584848c3e126b23e1e38af214412b9798d15a176080c67bafcc6796774e6352a.jpeg"
                        className="max-w-[760px] w-full data-img" alt="Selected Image" style={{opacity: 1}}/></div>
                </div>
            </div>

            {/*CAROUSEL -1-*/}
            <DigitalCarousel1 />

            {/*CAROUSEL -2-*/}
            <DigitalCarousel2 />

            {/*CAROUSEL -3-*/}
            <DigitalCarousel3 />

            {/*CAROUSEL -4-*/}
            <DigitalCarousel4 />

            {/*CAROUSEL -5-*/}
            <DigitalCarousel5 />

            {/*CAROUSEL -6-*/}
            <DigitalCarousel6 />


        </div>
);
}

export default DigitalContent;
