import React from 'react';
import '../Digital.css';

function DigitalHeader(props) {
    return (
        <div className="flex bg-gray-100 justify-between px-4 items-center h-[90px]">
            <div
                className="w-72 h-[50px] bg-white items-center z-50 cursor-pointer relative rounded-3xl pl-8 box-shadow flex lg:hidden">
                <img src="https://proj-assets.onedio.com/sunum/onedio.svg" alt="Onedio"
                     className="h-8 max-w-[100px]"/>
                <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                     className="false transition duration-500 absolute right-6 top-4">
                    <path d="M12.666 6L7.99935 10.6667L3.33268 6" stroke="currentColor" strokeWidth="1.32652"
                          strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
            </div>
            <div className="z-50 lg:hidden">
                <button
                    className="flex h-11 justify-center relative items-center text-base text-[#FD9790] px-2 pr-5 rounded-lg border border-[#FD9790] transition duration-500 hover:scale-105">TR
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                         className="absolute right-0.5 top-1/2 transform -translate-y-1/2">
                        <path d="M12.666 6L7.99935 10.6667L3.33268 6" stroke="currentColor" strokeWidth="1.32652"
                              strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                         className="absolute right-0.5 top-1/2 transform -translate-y-1/2">
                        <path d="M12.666 6L7.99935 10.6667L3.33268 6" stroke="currentColor" strokeWidth="1.32652"
                              strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                </button>
            </div>
            <div className="px-20 items-center justify-between w-full hidden lg:flex"><img
                src="https://proj-assets.onedio.com/sunum/mediazone.svg" alt="Mediazone Logo"/>
                <div className="flex items-center justify-center gap-8 relative h-13"><span
                    className="absolute bottom-0 top-0 z-0 flex overflow-hidden rounded-full shadow-lg transition-all duration-300"
                    style={{ left: '0px', width: '135px' }}><span
                    className="h-full w-full rounded-xl bg-white opacity-80"></span></span>
                    <button
                        className=" my-auto cursor-pointer select-none rounded-full w-auto px-4 text-center font-light text-white z-10">
                        <img src="https://proj-assets.onedio.com/sunum/onedio.svg"/></button>
                    <button
                        className="hover:text-neutral-300 my-auto cursor-pointer select-none rounded-full w-auto px-4 text-center font-light text-white z-10">
                        <img src="https://proj-assets.onedio.com/sunum/mynet.svg"/></button>
                    <button
                        className="hover:text-neutral-300 my-auto cursor-pointer select-none rounded-full w-auto px-4 text-center font-light text-white z-10">
                        <img src="https://proj-assets.onedio.com/sunum/mackolik.svg"/></button>
                    <button
                        className="hover:text-neutral-300 my-auto cursor-pointer select-none rounded-full w-auto px-4 text-center font-light text-white z-10">
                        <img src="https://proj-assets.onedio.com/sunum/webtekno.svg"/></button>
                </div>
                <div className="gap-3 hidden lg:flex">
                    <div className="z-50 undefined">
                        <button
                            className="flex h-11 justify-center relative items-center text-base text-white bg-[#FD9790] px-8 rounded-lg border-[#FD9790] transition duration-500 hover:scale-105">İletişim
                        </button>
                    </div>
                    <div className="z-50 undefined">
                        <button
                            style={{paddingRight: '1.25rem'}}
                            className="flex h-11 justify-center relative items-center text-base text-[#FD9790] pr-5 px-2  rounded-lg border border-[#FD9790] transition duration-500 hover:scale-105">TR
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                                 className="absolute right-0.5 transform -translate-y-1/2">
                                <path d="M12.666 6L7.99935 10.6667L3.33268 6" stroke="currentColor"
                                      strokeWidth="1.32652" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                                 className="absolute right-0.5 transform -translate-y-1/2">
                                <path d="M12.666 6L7.99935 10.6667L3.33268 6" stroke="currentColor"
                                      strokeWidth="1.32652" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DigitalHeader;
