import React from 'react';
import '../Digital.css';

function DigitalCarousel2(props) {
    return (
        <div className="w-full relative bg-[#FEF9F3]" id="projeler">
            <div
                className="max-w-full sm:max-w-[1440px] mx-auto my-8 pb-24 flex flex-col items-center justify-center gap-12 relative">
                <h2 className="h-20 relative text-[42px] text-center font-semibold mt-5">Projeler<img
                    src="https://srv-cdn.onedio.com/store/1b0abd9841cb6afa1be5884d8c5b6590341bdf4dc5d67dc7d59714245fcea8bc.png"
                    className="absolute -bottom-3 -right-5 sm:-right-12 w-40 h-11 scale-75 sm:scale-100"/></h2><p
                className="text-[#697883] text-base w-full xl:w-[950px] text-center">Onedio.com'da yayınlanan liste ve
                quiz içeriklerinden farklı olarak, teknik, tasarım ve content ekiplerinin çalışması sonucu oluşturulan,
                temel amacı marka ve kampanya bilinirliği arttırmak olan kullanıcı etkileşimli kurgulardır.</p>
                <div className="flex flex-col mx-auto gap-12 max-w-full sm:max-w-[1440px]">
                    <div
                        className="flex justify-start sm:justify-center items-start left-0 gap-1 sm:gap-6 h-20 absolute sm:relative sm:h-32 max-w-full sm:max-w-[1440px] overflow-x-auto sm:overflow-visible">
                        <div
                            className="bg-gray-100 w-auto lg:w-[250px] scale-75 sm:scale-100 pl-3 pr-2 ml-5 h-20 gap-3 flex-shrink-0 flex justify-start items-center rounded-xl border border-gray-200 cursor-pointer transition-transform duration-500 hover:bg-white hover:-translate-y-3 bg-white box-shadow">
                            <div className="rounded-full w-13 h-13 flex-shrink-0"
                                 style={{ backgroundColor: 'rgb(255, 210, 166)' }}></div>
                            <div className="font-semibold text-base">Content Interaction Projects</div>
                        </div>
                        <div
                            className="bg-gray-100 w-auto lg:w-[250px] scale-75 sm:scale-100 pl-3 pr-2 ml-5 h-20 gap-3 flex-shrink-0 flex justify-start items-center rounded-xl border border-gray-200 cursor-pointer transition-transform duration-500 hover:bg-white hover:-translate-y-3 false">
                            <div className="rounded-full w-13 h-13 flex-shrink-0"
                                 style={{ backgroundColor: 'rgb(255, 183, 183)' }}></div>
                            <div className="font-semibold text-base">Rich Media</div>
                        </div>
                        <div
                            className="bg-gray-100 w-auto lg:w-[250px] scale-75 sm:scale-100 pl-3 pr-2 ml-5 h-20 gap-3 flex-shrink-0 flex justify-start items-center rounded-xl border border-gray-200 cursor-pointer transition-transform duration-500 hover:bg-white hover:-translate-y-3 false">
                            <div className="rounded-full w-13 h-13 flex-shrink-0"
                                 style={{ backgroundColor: 'rgb(195, 238, 223)' }}></div>
                            <div className="font-semibold text-base">Technology Based Special Project</div>
                        </div>
                        <div
                            className="bg-gray-100 w-auto lg:w-[250px] scale-75 sm:scale-100 pl-3 pr-2 ml-5 h-20 gap-3 flex-shrink-0 flex justify-start items-center rounded-xl border border-gray-200 cursor-pointer transition-transform duration-500 hover:bg-white hover:-translate-y-3 false">
                            <div className="rounded-full w-13 h-13 flex-shrink-0"
                                 style={{ backgroundColor: 'rgb(214, 221, 252)' }}></div>
                            <div className="font-semibold text-base">Award Winning Projects</div>
                        </div>
                    </div>
                    <div className="mt-24 sm:mt-0" style={{ width: '100%', marginInline: 'auto' }}>
                        <div className="swiper-container swiper-container-initialized swiper-container-horizontal">
                            <div className="swiper-button-prev"></div>
                            <div className="swiper-button-next"></div>
                            <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets">
                                <span className="swiper-pagination-bullet swiper-pagination-bullet-active"></span><span
                                className="swiper-pagination-bullet"></span><span
                                className="swiper-pagination-bullet"></span><span
                                className="swiper-pagination-bullet"></span><span
                                className="swiper-pagination-bullet"></span><span
                                className="swiper-pagination-bullet"></span><span
                                className="swiper-pagination-bullet"></span></div>
                            <div className="swiper-wrapper"
                                 style={{ transitionDuration: '0ms', transform: 'translate3d(-4350px, 0px, 0px)' }}>
                                <div className="swiper-slide swiper-slide-duplicate" data-index="4"
                                     data-swiper-slide-index="4" style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg"></div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/1ff74a6da1cbaf7337bebaa8e7cfc8f0f107d001e1dbf591c930070ea96ecf81.png"
                                            className="h-auto sm:h-full"/>
                                            <div
                                                className="absolute -right-3 sm:-right-6 -bottom-8 scale-75 sm:scale-100 sm:-bottom-16 bg-[#fb968f] min-w-44 pl-4 pr-16 w-auto h-12 text-white text-md flex justify-start items-center rounded-3xl">Simple
                                                <div
                                                    className="absolute -right-4 top-1/2 transform -translate-y-1/2 bg-[#fb968f] w-16 h-16 rounded-full flex justify-center items-center">
                                                    <img
                                                        src="https://srv-cdn.onedio.com/store/c012124bfed751134089144ecb8cccb22d318d05a796da5e152634a569ebc31b.png"
                                                        className="w-4 h-4" loading="lazy"/></div>
                                            </div></div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate" data-index="5"
                                     data-swiper-slide-index="5" style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg"></div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/577dca10f23e7eaa75e1ab6a983c93631a0361ca0e25d1d489940bfebb99e9b8.png"
                                            className="h-auto sm:h-full"/>
                                            <div
                                                className="absolute -right-3 sm:-right-6 -bottom-8 scale-75 sm:scale-100 sm:-bottom-16 bg-[#fb968f] min-w-44 pl-4 pr-16 w-auto h-12 text-white text-md flex justify-start items-center rounded-3xl">Ülker
                                                Mini
                                                <div
                                                    className="absolute -right-4 top-1/2 transform -translate-y-1/2 bg-[#fb968f] w-16 h-16 rounded-full flex justify-center items-center">
                                                    <img
                                                        src="https://srv-cdn.onedio.com/store/c012124bfed751134089144ecb8cccb22d318d05a796da5e152634a569ebc31b.png"
                                                        className="w-4 h-4" loading="lazy"/></div>
                                            </div></div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate swiper-slide-prev" data-index="6"
                                     data-swiper-slide-index="6" style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg"></div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/0fb2e560eae1b27d016af2ef960d01991fd50aa72a1c4b65cef6afa7b7d7c17b.png"
                                            className="h-auto sm:h-full"/>
                                            <div
                                                className="absolute -right-3 sm:-right-6 -bottom-8 scale-75 sm:scale-100 sm:-bottom-16 bg-[#fb968f] min-w-44 pl-4 pr-16 w-auto h-12 text-white text-md flex justify-start items-center rounded-3xl">Ülker
                                                Napoliten
                                                <div
                                                    className="absolute -right-4 top-1/2 transform -translate-y-1/2 bg-[#fb968f] w-16 h-16 rounded-full flex justify-center items-center">
                                                    <img
                                                        src="https://srv-cdn.onedio.com/store/c012124bfed751134089144ecb8cccb22d318d05a796da5e152634a569ebc31b.png"
                                                        className="w-4 h-4" loading="lazy"/></div>
                                            </div></div>
                                </div>
                                <div className="swiper-slide swiper-slide-active" data-index="0"
                                     data-swiper-slide-index="0" style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg"></div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/cefa5752a064e8ef82b342dc7a9e9a7f3524aa119a743c357f94153f98f7b66a.png"
                                            className="h-auto sm:h-full"/>
                                            <div
                                                className="absolute -right-3 sm:-right-6 -bottom-8 scale-75 sm:scale-100 sm:-bottom-16 bg-[#fb968f] min-w-44 pl-4 pr-16 w-auto h-12 text-white text-md flex justify-start items-center rounded-3xl">Erikli
                                                <div
                                                    className="absolute -right-4 transform -translate-y-1/2 bg-[#fb968f] w-16 h-16 rounded-full flex justify-center items-center">
                                                    <img
                                                        src="https://srv-cdn.onedio.com/store/c012124bfed751134089144ecb8cccb22d318d05a796da5e152634a569ebc31b.png"
                                                        className="w-4 h-4" loading="lazy"/></div>
                                            </div></div>
                                </div>
                                <div className="swiper-slide swiper-slide-next" data-index="1"
                                     data-swiper-slide-index="1" style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg"></div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/bf50270b2a1b4feeee358913cb583f62842862cbe90d78887b86422c32bc72f7.png"
                                            className="h-auto sm:h-full"/>
                                            <div
                                                className="absolute -right-3 sm:-right-6 -bottom-8 scale-75 sm:scale-100 sm:-bottom-16 bg-[#fb968f] min-w-44 pl-4 pr-16 w-auto h-12 text-white text-md flex justify-start items-center rounded-3xl">Axe
                                                Black Night
                                                <div
                                                    className="absolute -right-4 top-1/2 transform -translate-y-1/2 bg-[#fb968f] w-16 h-16 rounded-full flex justify-center items-center">
                                                    <img
                                                        src="https://srv-cdn.onedio.com/store/c012124bfed751134089144ecb8cccb22d318d05a796da5e152634a569ebc31b.png"
                                                        className="w-4 h-4" loading="lazy"/></div>
                                            </div></div>
                                </div>
                                <div className="swiper-slide" data-index="2" data-swiper-slide-index="2"
                                     style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg"></div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/bb3575fa93533092efdbb2f32337258eeac3403d300fcf490846a4145cdd837c.png"
                                            className="h-auto sm:h-full"/>
                                            <div
                                                className="absolute -right-3 sm:-right-6 -bottom-8 scale-75 sm:scale-100 sm:-bottom-16 bg-[#fb968f] min-w-44 pl-4 pr-16 w-auto h-12 text-white text-md flex justify-start items-center rounded-3xl">Samsung
                                                <div
                                                    className="absolute -right-4 top-1/2 transform -translate-y-1/2 bg-[#fb968f] w-16 h-16 rounded-full flex justify-center items-center">
                                                    <img
                                                        src="https://srv-cdn.onedio.com/store/c012124bfed751134089144ecb8cccb22d318d05a796da5e152634a569ebc31b.png"
                                                        className="w-4 h-4" loading="lazy"/></div>
                                            </div></div>
                                </div>
                                <div className="swiper-slide" data-index="3" data-swiper-slide-index="3"
                                     style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg"></div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/3e572da4e7c986ae8293ba3f66f23004bdae9e833a9beba2e1975431b6bcefe5.png"
                                            className="h-auto sm:h-full"/>
                                            <div
                                                className="absolute -right-3 sm:-right-6 -bottom-8 scale-75 sm:scale-100 sm:-bottom-16 bg-[#fb968f] min-w-44 pl-4 pr-16 w-auto h-12 text-white text-md flex justify-start items-center rounded-3xl">Pandora
                                                <div
                                                    className="absolute -right-4 top-1/2 transform -translate-y-1/2 bg-[#fb968f] w-16 h-16 rounded-full flex justify-center items-center">
                                                    <img
                                                        src="https://srv-cdn.onedio.com/store/c012124bfed751134089144ecb8cccb22d318d05a796da5e152634a569ebc31b.png"
                                                        className="w-4 h-4" loading="lazy"/></div>
                                            </div></div>
                                </div>
                                <div className="swiper-slide" data-index="4" data-swiper-slide-index="4"
                                     style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg"></div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/1ff74a6da1cbaf7337bebaa8e7cfc8f0f107d001e1dbf591c930070ea96ecf81.png"
                                            className="h-auto sm:h-full"/>
                                            <div
                                                className="absolute -right-3 sm:-right-6 -bottom-8 scale-75 sm:scale-100 sm:-bottom-16 bg-[#fb968f] min-w-44 pl-4 pr-16 w-auto h-12 text-white text-md flex justify-start items-center rounded-3xl">Simple
                                                <div
                                                    className="absolute -right-4 top-1/2 transform -translate-y-1/2 bg-[#fb968f] w-16 h-16 rounded-full flex justify-center items-center">
                                                    <img
                                                        src="https://srv-cdn.onedio.com/store/c012124bfed751134089144ecb8cccb22d318d05a796da5e152634a569ebc31b.png"
                                                        className="w-4 h-4" loading="lazy"/></div>
                                            </div></div>
                                </div>
                                <div className="swiper-slide" data-index="5" data-swiper-slide-index="5"
                                     style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg"></div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/577dca10f23e7eaa75e1ab6a983c93631a0361ca0e25d1d489940bfebb99e9b8.png"
                                            className="h-auto sm:h-full"/>
                                            <div
                                                className="absolute -right-3 sm:-right-6 -bottom-8 scale-75 sm:scale-100 sm:-bottom-16 bg-[#fb968f] min-w-44 pl-4 pr-16 w-auto h-12 text-white text-md flex justify-start items-center rounded-3xl">Ülker
                                                Mini
                                                <div
                                                    className="absolute -right-4 top-1/2 transform -translate-y-1/2 bg-[#fb968f] w-16 h-16 rounded-full flex justify-center items-center">
                                                    <img
                                                        src="https://srv-cdn.onedio.com/store/c012124bfed751134089144ecb8cccb22d318d05a796da5e152634a569ebc31b.png"
                                                        className="w-4 h-4" loading="lazy"/></div>
                                            </div></div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate-prev" data-index="6"
                                     data-swiper-slide-index="6" style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg"></div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/0fb2e560eae1b27d016af2ef960d01991fd50aa72a1c4b65cef6afa7b7d7c17b.png"
                                            className="h-auto sm:h-full"/>
                                            <div
                                                className="absolute -right-3 sm:-right-6 -bottom-8 scale-75 sm:scale-100 sm:-bottom-16 bg-[#fb968f] min-w-44 pl-4 pr-16 w-auto h-12 text-white text-md flex justify-start items-center rounded-3xl">Ülker
                                                Napoliten
                                                <div
                                                    className="absolute -right-4 top-1/2 transform -translate-y-1/2 bg-[#fb968f] w-16 h-16 rounded-full flex justify-center items-center">
                                                    <img
                                                        src="https://srv-cdn.onedio.com/store/c012124bfed751134089144ecb8cccb22d318d05a796da5e152634a569ebc31b.png"
                                                        className="w-4 h-4" loading="lazy"/></div>
                                            </div></div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
                                     data-index="0" data-swiper-slide-index="0"
                                     style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg"></div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/cefa5752a064e8ef82b342dc7a9e9a7f3524aa119a743c357f94153f98f7b66a.png"
                                            className="h-auto sm:h-full"/>
                                            <div
                                                className="absolute -right-3 sm:-right-6 -bottom-8 scale-75 sm:scale-100 sm:-bottom-16 bg-[#fb968f] min-w-44 pl-4 pr-16 w-auto h-12 text-white text-md flex justify-start items-center rounded-3xl">Erikli
                                                <div
                                                    className="absolute -right-4 top-1/2 transform -translate-y-1/2 bg-[#fb968f] w-16 h-16 rounded-full flex justify-center items-center">
                                                    <img
                                                        src="https://srv-cdn.onedio.com/store/c012124bfed751134089144ecb8cccb22d318d05a796da5e152634a569ebc31b.png"
                                                        className="w-4 h-4" loading="lazy"/></div>
                                            </div></div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next"
                                     data-index="1" data-swiper-slide-index="1"
                                     style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg"></div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/bf50270b2a1b4feeee358913cb583f62842862cbe90d78887b86422c32bc72f7.png"
                                            className="h-auto sm:h-full"/>
                                            <div
                                                className="absolute -right-3 sm:-right-6 -bottom-8 scale-75 sm:scale-100 sm:-bottom-16 bg-[#fb968f] min-w-44 pl-4 pr-16 w-auto h-12 text-white text-md flex justify-start items-center rounded-3xl">Axe
                                                Black Night
                                                <div
                                                    className="absolute -right-4 top-1/2 transform -translate-y-1/2 bg-[#fb968f] w-16 h-16 rounded-full flex justify-center items-center">
                                                    <img
                                                        src="https://srv-cdn.onedio.com/store/c012124bfed751134089144ecb8cccb22d318d05a796da5e152634a569ebc31b.png"
                                                        className="w-4 h-4" loading="lazy"/></div>
                                            </div></div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate" data-index="2"
                                     data-swiper-slide-index="2" style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg"></div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/bb3575fa93533092efdbb2f32337258eeac3403d300fcf490846a4145cdd837c.png"
                                            className="h-auto sm:h-full"/>
                                            <div
                                                className="absolute -right-3 sm:-right-6 -bottom-8 scale-75 sm:scale-100 sm:-bottom-16 bg-[#fb968f] min-w-44 pl-4 pr-16 w-auto h-12 text-white text-md flex justify-start items-center rounded-3xl">Samsung
                                                <div
                                                    className="absolute -right-4 top-1/2 transform -translate-y-1/2 bg-[#fb968f] w-16 h-16 rounded-full flex justify-center items-center">
                                                    <img
                                                        src="https://srv-cdn.onedio.com/store/c012124bfed751134089144ecb8cccb22d318d05a796da5e152634a569ebc31b.png"
                                                        className="w-4 h-4" loading="lazy"/></div>
                                            </div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DigitalCarousel2;
