import React from 'react';

function DigitalMenu(props) {
    return (
        <div
            className="submenu-header bg-white h-14 sm:h-[90px] shadow-lg sticky top-0 flex items-center justify-center px-3 lg:px-20 header-visible">
            <ul className="flex gap-8 justify-start lg:justify-center absolute w-11/12 lg:w-full overflow-x-auto items-center">
                <li className="text-[#81838C] flex-shrink-0 text-base pb-1 cursor-pointer hover:text-black after:bg-gray-700 after:absolute after:h-0.5 after:w-0 after:bottom-0 after:left-0 hover:after:w-3 after:transition-all after:duration-300 relative">
                    <a href="#datalar">Datalar</a></li>
                <li className="text-[#81838C] flex-shrink-0 text-base pb-1 cursor-pointer hover:text-black after:bg-gray-700 after:absolute after:h-0.5 after:w-0 after:bottom-0 after:left-0 hover:after:w-3 after:transition-all after:duration-300 relative">
                    <a href="#icerikler">İçerikler</a></li>
                <li className="text-[#81838C] flex-shrink-0 text-base pb-1 cursor-pointer hover:text-black after:bg-gray-700 after:absolute after:h-0.5 after:w-0 after:bottom-0 after:left-0 hover:after:w-3 after:transition-all after:duration-300 relative">
                    <a href="#projeler">Projeler</a></li>
                <li className="text-[#81838C] flex-shrink-0 text-base pb-1 cursor-pointer hover:text-black after:bg-gray-700 after:absolute after:h-0.5 after:w-0 after:bottom-0 after:left-0 hover:after:w-3 after:transition-all after:duration-300 relative">
                    <a href="#videolar">Videolar</a></li>
                <li className="text-[#81838C] flex-shrink-0 text-base pb-1 cursor-pointer hover:text-black after:bg-gray-700 after:absolute after:h-0.5 after:w-0 after:bottom-0 after:left-0 hover:after:w-3 after:transition-all after:duration-300 relative">
                    <a href="#yemekler">Yemek</a></li>
                <li className="text-[#81838C] flex-shrink-0 text-base pb-1 cursor-pointer hover:text-black after:bg-gray-700 after:absolute after:h-0.5 after:w-0 after:bottom-0 after:left-0 hover:after:w-3 after:transition-all after:duration-300 relative">
                    <a href="#vitrin">Vitrin</a></li>
                <li className="text-[#81838C] flex-shrink-0 text-base pb-1 cursor-pointer hover:text-black after:bg-gray-700 after:absolute after:h-0.5 after:w-0 after:bottom-0 after:left-0 hover:after:w-3 after:transition-all after:duration-300 relative">
                    <a href="#display-programatik">Display &amp; Programatik</a></li>
            </ul>
        </div>
    );
}

export default DigitalMenu;
