import React from 'react';

function DigitalCarousel5(props) {
    return (
        <div className="w-full relative bg-[#ffefdd]" id="vitrin">
            <div
                className="max-w-full sm:max-w-[1440px] mx-auto my-8 pb-24 flex flex-col items-center justify-center gap-12 relative">
                <h2 className="h-20 relative text-[42px] text-center font-semibold mt-5">Vitrin<img
                    src="https://srv-cdn.onedio.com/store/1b0abd9841cb6afa1be5884d8c5b6590341bdf4dc5d67dc7d59714245fcea8bc.png"
                    className="absolute -bottom-3 -right-5 sm:-right-12 w-40 h-11 scale-75 sm:scale-100"/></h2><p
                className="text-[#697883] text-base w-full xl:w-[950px] text-center">Mynet.com’da yayınlanan liste
                içeriklerine benzer olarak oluşturulan, temel amacı marka ve kampanya bilinirliği arttırmak olan
                kullanıcıya ilgi alanındaki ürünlerin satış linklerini sunan etkileşimli kurgulardır.</p>
                <div className="flex flex-col mx-auto gap-12 max-w-full sm:max-w-[1440px]">
                    <div
                        className="flex justify-start sm:justify-center items-start left-0 gap-1 sm:gap-6 h-20 absolute sm:relative sm:h-32 max-w-full sm:max-w-[1440px] overflow-x-auto sm:overflow-visible">
                        <div
                            className="bg-gray-100 w-auto lg:w-[250px] scale-75 sm:scale-100 pl-3 pr-2 ml-5 h-20 gap-3 flex-shrink-0 flex justify-start items-center rounded-xl border border-gray-200 cursor-pointer transition-transform duration-500 hover:bg-white hover:-translate-y-3 bg-white box-shadow">
                            <div className="rounded-full w-13 h-13 flex-shrink-0"
                                 style={{ backgroundColor: 'rgb(255, 210, 166)' }}></div>
                            <div className="font-semibold text-base">Kategori</div>
                        </div>
                        <div
                            className="bg-gray-100 w-auto lg:w-[250px] scale-75 sm:scale-100 pl-3 pr-2 ml-5 h-20 gap-3 flex-shrink-0 flex justify-start items-center rounded-xl border border-gray-200 cursor-pointer transition-transform duration-500 hover:bg-white hover:-translate-y-3 false">
                            <div className="rounded-full w-13 h-13 flex-shrink-0"
                                 style={{ backgroundColor: 'rgb(255, 183, 183)' }}></div>
                            <div className="font-semibold text-base">Affilliate</div>
                        </div>
                        <div
                            className="bg-gray-100 w-auto lg:w-[250px] scale-75 sm:scale-100 pl-3 pr-2 ml-5 h-20 gap-3 flex-shrink-0 flex justify-start items-center rounded-xl border border-gray-200 cursor-pointer transition-transform duration-500 hover:bg-white hover:-translate-y-3 false">
                            <div className="rounded-full w-13 h-13 flex-shrink-0"
                                 style={{ backgroundColor: 'rgb(195, 238, 223)' }}></div>
                            <div className="font-semibold text-base">Madde Entegrasyonu</div>
                        </div>
                    </div>
                    <div className="mt-24 sm:mt-0" style={{ width: '100%', marginInline: 'auto' }}>
                        <div className="swiper-container swiper-container-initialized swiper-container-horizontal">
                            <div className="swiper-button-prev"></div>
                            <div className="swiper-button-next"></div>
                            <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets">
                                <span className="swiper-pagination-bullet swiper-pagination-bullet-active"></span><span
                                className="swiper-pagination-bullet"></span><span
                                className="swiper-pagination-bullet"></span><span
                                className="swiper-pagination-bullet"></span><span
                                className="swiper-pagination-bullet"></span></div>
                            <div className="swiper-wrapper"
                                 style={{ transform: 'translate3d(-4350px, 0px, 0px)', transitionDuration: '0ms' }}>
                                <div className="swiper-slide swiper-slide-duplicate" data-index="2"
                                     data-swiper-slide-index="2" style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg">Philips Vitrin Kategorisi - Pageskin
                                        </div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/832150958fac5cacd1194625bb3d59e8d885f1e803f95778fb3aefa9573a10ff.png"
                                            className="h-auto sm:h-full"/>
                                            <div
                                                className="absolute -right-3 sm:-right-6 -bottom-8 scale-75 sm:scale-100 sm:-bottom-16 bg-[#fb968f] min-w-44 pl-4 pr-16 w-auto h-12 text-white text-md flex justify-start items-center rounded-3xl">Philips
                                                <div
                                                    className="absolute -right-4 top-1/2 transform -translate-y-1/2 bg-[#fb968f] w-16 h-16 rounded-full flex justify-center items-center">
                                                    <img
                                                        src="https://srv-cdn.onedio.com/store/c012124bfed751134089144ecb8cccb22d318d05a796da5e152634a569ebc31b.png"
                                                        className="w-4 h-4" loading="lazy"/></div>
                                            </div></div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate" data-index="3"
                                     data-swiper-slide-index="3" style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg">Gratis Vitrin Kategorisi - Pageskin</div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/02ded75f015e2cc7725fabf1fcc46de8b94c4b04a8e6ae6ca2c33cb48247d254.png"
                                            className="h-auto sm:h-full"/>
                                            <div
                                                className="absolute -right-3 sm:-right-6 -bottom-8 scale-75 sm:scale-100 sm:-bottom-16 bg-[#fb968f] min-w-44 pl-4 pr-16 w-auto h-12 text-white text-md flex justify-start items-center rounded-3xl">Gratis
                                                <div
                                                    className="absolute -right-4 top-1/2 transform -translate-y-1/2 bg-[#fb968f] w-16 h-16 rounded-full flex justify-center items-center">
                                                    <img
                                                        src="https://srv-cdn.onedio.com/store/c012124bfed751134089144ecb8cccb22d318d05a796da5e152634a569ebc31b.png"
                                                        className="w-4 h-4" loading="lazy"/></div>
                                            </div></div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate swiper-slide-prev" data-index="4"
                                     data-swiper-slide-index="4" style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg">THY Vitrin Kategorisi - Pageskin</div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/50b477617b27b6c6d15682ad8148a8b68ace668529360b556ee3f23fbb7c720f.png"
                                            className="h-auto sm:h-full"/>
                                            <div
                                                className="absolute -right-3 sm:-right-6 -bottom-8 scale-75 sm:scale-100 sm:-bottom-16 bg-[#fb968f] min-w-44 pl-4 pr-16 w-auto h-12 text-white text-md flex justify-start items-center rounded-3xl">Thy
                                                <div
                                                    className="absolute -right-4 top-1/2 transform -translate-y-1/2 bg-[#fb968f] w-16 h-16 rounded-full flex justify-center items-center">
                                                    <img
                                                        src="https://srv-cdn.onedio.com/store/c012124bfed751134089144ecb8cccb22d318d05a796da5e152634a569ebc31b.png"
                                                        className="w-4 h-4" loading="lazy"/></div>
                                            </div></div>
                                </div>
                                <div className="swiper-slide swiper-slide-active" data-index="0"
                                     data-swiper-slide-index="0" style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg">Trendyol Vitrin Kategorisi - Pageskin
                                        </div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/d34f0c44d03b9f5cd554b661a3e3783d3b5bd10e99e2466fbfc4c7e93e66c862.png"
                                            className="h-auto sm:h-full"/>
                                            <div
                                                className="absolute -right-3 sm:-right-6 -bottom-8 scale-75 sm:scale-100 sm:-bottom-16 bg-[#fb968f] min-w-44 pl-4 pr-16 w-auto h-12 text-white text-md flex justify-start items-center rounded-3xl">Trendyol
                                                <div
                                                    className="absolute -right-4 transform -translate-y-1/2 bg-[#fb968f] w-16 h-16 rounded-full flex justify-center items-center">
                                                    <img
                                                        src="https://srv-cdn.onedio.com/store/c012124bfed751134089144ecb8cccb22d318d05a796da5e152634a569ebc31b.png"
                                                        className="w-4 h-4" loading="lazy"/></div>
                                            </div></div>
                                </div>
                                <div className="swiper-slide swiper-slide-next" data-index="1"
                                     data-swiper-slide-index="1" style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg">Tchino Vitrin Kategorisi - Pageskin</div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/6414bf8d93b5859eedfd0274d3c1ac6a9c5813ed08aa38370c5f59678151deba.png"
                                            className="h-auto sm:h-full"/>
                                            <div
                                                className="absolute -right-3 sm:-right-6 -bottom-8 scale-75 sm:scale-100 sm:-bottom-16 bg-[#fb968f] min-w-44 pl-4 pr-16 w-auto h-12 text-white text-md flex justify-start items-center rounded-3xl">Tchibo
                                                <div
                                                    className="absolute -right-4 top-1/2 transform -translate-y-1/2 bg-[#fb968f] w-16 h-16 rounded-full flex justify-center items-center">
                                                    <img
                                                        src="https://srv-cdn.onedio.com/store/c012124bfed751134089144ecb8cccb22d318d05a796da5e152634a569ebc31b.png"
                                                        className="w-4 h-4" loading="lazy"/></div>
                                            </div></div>
                                </div>
                                <div className="swiper-slide" data-index="2" data-swiper-slide-index="2"
                                     style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg">Philips Vitrin Kategorisi - Pageskin
                                        </div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/832150958fac5cacd1194625bb3d59e8d885f1e803f95778fb3aefa9573a10ff.png"
                                            className="h-auto sm:h-full"/>
                                            <div
                                                className="absolute -right-3 sm:-right-6 -bottom-8 scale-75 sm:scale-100 sm:-bottom-16 bg-[#fb968f] min-w-44 pl-4 pr-16 w-auto h-12 text-white text-md flex justify-start items-center rounded-3xl">Philips
                                                <div
                                                    className="absolute -right-4 top-1/2 transform -translate-y-1/2 bg-[#fb968f] w-16 h-16 rounded-full flex justify-center items-center">
                                                    <img
                                                        src="https://srv-cdn.onedio.com/store/c012124bfed751134089144ecb8cccb22d318d05a796da5e152634a569ebc31b.png"
                                                        className="w-4 h-4" loading="lazy"/></div>
                                            </div></div>
                                </div>
                                <div className="swiper-slide" data-index="3" data-swiper-slide-index="3"
                                     style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg">Gratis Vitrin Kategorisi - Pageskin</div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/02ded75f015e2cc7725fabf1fcc46de8b94c4b04a8e6ae6ca2c33cb48247d254.png"
                                            className="h-auto sm:h-full"/>
                                            <div
                                                className="absolute -right-3 sm:-right-6 -bottom-8 scale-75 sm:scale-100 sm:-bottom-16 bg-[#fb968f] min-w-44 pl-4 pr-16 w-auto h-12 text-white text-md flex justify-start items-center rounded-3xl">Gratis
                                                <div
                                                    className="absolute -right-4 top-1/2 transform -translate-y-1/2 bg-[#fb968f] w-16 h-16 rounded-full flex justify-center items-center">
                                                    <img
                                                        src="https://srv-cdn.onedio.com/store/c012124bfed751134089144ecb8cccb22d318d05a796da5e152634a569ebc31b.png"
                                                        className="w-4 h-4" loading="lazy"/></div>
                                            </div></div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate-prev" data-index="4"
                                     data-swiper-slide-index="4" style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg">THY Vitrin Kategorisi - Pageskin</div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/50b477617b27b6c6d15682ad8148a8b68ace668529360b556ee3f23fbb7c720f.png"
                                            className="h-auto sm:h-full"/>
                                            <div
                                                className="absolute -right-3 sm:-right-6 -bottom-8 scale-75 sm:scale-100 sm:-bottom-16 bg-[#fb968f] min-w-44 pl-4 pr-16 w-auto h-12 text-white text-md flex justify-start items-center rounded-3xl">Thy
                                                <div
                                                    className="absolute -right-4 top-1/2 transform -translate-y-1/2 bg-[#fb968f] w-16 h-16 rounded-full flex justify-center items-center">
                                                    <img
                                                        src="https://srv-cdn.onedio.com/store/c012124bfed751134089144ecb8cccb22d318d05a796da5e152634a569ebc31b.png"
                                                        className="w-4 h-4" loading="lazy"/></div>
                                            </div></div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
                                     data-index="0" data-swiper-slide-index="0"
                                     style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg">Trendyol Vitrin Kategorisi - Pageskin
                                        </div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/d34f0c44d03b9f5cd554b661a3e3783d3b5bd10e99e2466fbfc4c7e93e66c862.png"
                                            className="h-auto sm:h-full"/>
                                            <div
                                                className="absolute -right-3 sm:-right-6 -bottom-8 scale-75 sm:scale-100 sm:-bottom-16 bg-[#fb968f] min-w-44 pl-4 pr-16 w-auto h-12 text-white text-md flex justify-start items-center rounded-3xl">Trendyol
                                                <div
                                                    className="absolute -right-4 top-1/2 transform -translate-y-1/2 bg-[#fb968f] w-16 h-16 rounded-full flex justify-center items-center">
                                                    <img
                                                        src="https://srv-cdn.onedio.com/store/c012124bfed751134089144ecb8cccb22d318d05a796da5e152634a569ebc31b.png"
                                                        className="w-4 h-4" loading="lazy"/></div>
                                            </div></div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next"
                                     data-index="1" data-swiper-slide-index="1"
                                     style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg">Tchino Vitrin Kategorisi - Pageskin</div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/6414bf8d93b5859eedfd0274d3c1ac6a9c5813ed08aa38370c5f59678151deba.png"
                                            className="h-auto sm:h-full"/>
                                            <div
                                                className="absolute -right-3 sm:-right-6 -bottom-8 scale-75 sm:scale-100 sm:-bottom-16 bg-[#fb968f] min-w-44 pl-4 pr-16 w-auto h-12 text-white text-md flex justify-start items-center rounded-3xl">Tchibo
                                                <div
                                                    className="absolute -right-4 top-1/2 transform -translate-y-1/2 bg-[#fb968f] w-16 h-16 rounded-full flex justify-center items-center">
                                                    <img
                                                        src="https://srv-cdn.onedio.com/store/c012124bfed751134089144ecb8cccb22d318d05a796da5e152634a569ebc31b.png"
                                                        className="w-4 h-4" loading="lazy"/></div>
                                            </div></div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate" data-index="2"
                                     data-swiper-slide-index="2" style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg">Philips Vitrin Kategorisi - Pageskin
                                        </div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/832150958fac5cacd1194625bb3d59e8d885f1e803f95778fb3aefa9573a10ff.png"
                                            className="h-auto sm:h-full"/>
                                            <div
                                                className="absolute -right-3 sm:-right-6 -bottom-8 scale-75 sm:scale-100 sm:-bottom-16 bg-[#fb968f] min-w-44 pl-4 pr-16 w-auto h-12 text-white text-md flex justify-start items-center rounded-3xl">Philips
                                                <div
                                                    className="absolute -right-4 top-1/2 transform -translate-y-1/2 bg-[#fb968f] w-16 h-16 rounded-full flex justify-center items-center">
                                                    <img
                                                        src="https://srv-cdn.onedio.com/store/c012124bfed751134089144ecb8cccb22d318d05a796da5e152634a569ebc31b.png"
                                                        className="w-4 h-4" loading="lazy"/></div>
                                            </div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DigitalCarousel5;
