import React, {Component, useContext, useEffect, useState} from 'react';
import TopMenus from "./TopMenus";
import './Header.css'
import '../../App.css'
import {Link, Outlet, useLocation, useNavigate} from 'react-router-dom'
import img from '../../assets/icons/instagram.png'
import img1 from '../../assets/icons/facebook.png'
import img2 from '../../assets/icons/twitter.png'
import fl from '../../assets/flags/TR.png'
import fl1 from '../../assets/flags/EN.png'
import fl2 from '../../assets/flags/DE.png'
import fl3 from '../../assets/flags/FR.png'
import fl4 from '../../assets/flags/ES.png'
import user from '../../assets/icons/user.png'
import logo from '../../assets/icons/logo.png'
import tvIcon from '../../assets/icons/tv-icon.png'
import {slide as Menu} from 'react-burger-menu'
import Button from "@mui/material/Button";
import {AppContext} from "../../context/AppContext";
import MobileHamburgerMenu from "./mobile-hamburger-menu/MobileHamburgerMenu";
import GeneralWebHeader from "./web-header/GeneralWebHeader";
import GeneralMobileHeader from "./mobile-header/GeneralMobileHeader";
import HomePageMobileHeader from "./mobile-header/HomePageMobileHeader";

function Header(props) {
    const {isPro, lang} = useContext(AppContext);
    const navigate = useNavigate();
    const userDetail = localStorage.getItem('user');
    const user = JSON.parse(userDetail);

    const currentRoutes = useLocation();

    const pathName = currentRoutes.pathname;
    const joinPremiumButton = () => {
        if (pathName !== '/sign-in' && pathName !== '/sign-up') {
            if (!user) {
                return (
                    <div className='fixed-join-premium-container'
                         onClick={() => toPremium()}
                    >
                        <span className='fixed-join-premium-text'>Join to Futbol Verileri Premium</span>
                    </div>
                )
            } else if (!isPro) {
                return (
                    <div className='fixed-join-premium-container'
                         onClick={() => toPremium()}
                    >
                        <span className='fixed-join-premium-text'>Join to Futbol Verileri Premium</span>
                    </div>
                )
            }
        }
    }

    const toPremium = () => {
        navigate('/join-premium');
    }

    const location = useLocation();
    const [count, setCount] = useState(0);
    const isLmtOn = pathName.includes('/fv-lmt-v3');

    useEffect(() => {
        // execute on location change
        setCount(count + 1);
    }, [location]);

    return (
        <div className={isLmtOn ? 'isLmtOn' : 'isLmtOff'}>
            {
                location.pathname === '/join-premium' &&
                <div className="join-premium-header-container">
                    <div>
                        <Link to={'/'} className="site-logo-text">
                            <img className={'logo'} src={logo} alt="user"/>
                        </Link>
                    </div>
                </div>
            }
            {
                location.pathname !== `/join-premium` &&
                <div>
                    {joinPremiumButton()}

                    <div className="mid-header-section">
                        {/*FOR MOBILE*/}
                        {
                            location.pathname !== '/' &&
                            <GeneralMobileHeader />
                        }

                        {
                            location.pathname === '/' &&
                            <div className='mobile-header-section'>
                                <HomePageMobileHeader/>
                            </div>
                        }

                        {/*FOR WEB*/}
                        <GeneralWebHeader />
                    </div>
                    <div className="top-navigation-bar">
                        <div className="const-width">
                            <TopMenus></TopMenus>
                        </div>
                    </div>
                    <Outlet/>
                </div>
            }
        </div>
    );
}

export default Header;
