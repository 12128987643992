import React from 'react';

function DigitalCarousel3(props) {
    return (
        <div className="w-full relative bg-[#F3FCF6]" id="videolar">
            <div
                className="max-w-full sm:max-w-[1440px] mx-auto my-8 pb-24 flex flex-col items-center justify-center gap-12 relative">
                <h2 className="h-20 relative text-[42px] text-center font-semibold mt-5">Videolar<img
                    src="https://srv-cdn.onedio.com/store/1b0abd9841cb6afa1be5884d8c5b6590341bdf4dc5d67dc7d59714245fcea8bc.png"
                    className="absolute -bottom-3 -right-5 sm:-right-12 w-40 h-11 scale-75 sm:scale-100"/></h2><p
                className="text-[#697883] text-base w-full xl:w-[950px] text-center">Youtube, Instagram, Tiktok,
                Facebook, Belgesel, New-Gen Video, Dizi ya da Canlı Yayın. Onedio Video bugne kadar 20'den fazla trde,
                2000’den fazla video retti ve <span style={{ color: '#E26F67', fontWeight: 600 }}>2 milyardan fazla izlenme aldı.</span> Kreatif,
                Prodksiyon, Post Prodksiyon ve Medya’yı tek elden birleştiren ender ekiplerden biri. Hem hızlı hem de
                cost-efektif!</p>
                <div className="flex flex-col mx-auto gap-12 max-w-full sm:max-w-[1440px]">
                    <div
                        className="flex justify-start sm:justify-center items-start left-0 gap-1 sm:gap-6 h-20 absolute sm:relative sm:h-32 max-w-full sm:max-w-[1440px] overflow-x-auto sm:overflow-visible"
                    >
                        <div
                            className="bg-gray-100 w-auto lg:w-[250px] scale-75 sm:scale-100 pl-3 pr-2 ml-5 h-20 gap-3 flex-shrink-0 flex justify-start items-center rounded-xl border border-gray-200 cursor-pointer transition-transform duration-500 hover:bg-white hover:-translate-y-3 bg-white box-shadow">
                            <div className="rounded-full w-13 h-13 flex-shrink-0"
                                 style={{backgroundColor: 'rgb(255, 210, 166)'}}></div>
                            <div className="font-semibold text-base">Video Tanıtım</div>
                        </div>

                        <div
                            className="bg-gray-100 w-auto lg:w-[250px] scale-75 sm:scale-100 pl-3 pr-2 ml-5 h-20 gap-3 flex-shrink-0 flex justify-start items-center rounded-xl border border-gray-200 cursor-pointer transition-transform duration-500 hover:bg-white hover:-translate-y-3"
                        >
                            <div
                                className="rounded-full w-13 h-13 flex-shrink-0"
                                style={{backgroundColor: 'rgb(255, 183, 183)'}}
                            ></div>
                            <div className="font-semibold text-base">Newgen</div>
                        </div>

                        <div
                            className="bg-gray-100 w-auto lg:w-[250px] scale-75 sm:scale-100 pl-3 pr-2 ml-5 h-20 gap-3 flex-shrink-0 flex justify-start items-center rounded-xl border border-gray-200 cursor-pointer transition-transform duration-500 hover:bg-white hover:-translate-y-3"
                        >
                            <div
                                className="rounded-full w-13 h-13 flex-shrink-0"
                                style={{backgroundColor: 'rgb(195, 238, 223)'}}
                            ></div>
                            <div className="font-semibold text-base">Insight Video</div>
                        </div>

                        <div
                            className="bg-gray-100 w-auto lg:w-[250px] scale-75 sm:scale-100 pl-3 pr-2 ml-5 h-20 gap-3 flex-shrink-0 flex justify-start items-center rounded-xl border border-gray-200 cursor-pointer transition-transform duration-500 hover:bg-white hover:-translate-y-3"
                        >
                            <div
                                className="rounded-full w-13 h-13 flex-shrink-0"
                                style={{backgroundColor: 'rgb(214, 221, 252)'}}
                            ></div>
                            <div className="font-semibold text-base">Fon Format Video</div>
                        </div>

                        <div
                            className="bg-gray-100 w-auto lg:w-[250px] scale-75 sm:scale-100 pl-3 pr-2 ml-5 h-20 gap-3 flex-shrink-0 flex justify-start items-center rounded-xl border border-gray-200 cursor-pointer transition-transform duration-500 hover:bg-white hover:-translate-y-3"
                        >
                            <div
                                className="rounded-full w-13 h-13 flex-shrink-0"
                                style={{backgroundColor: 'rgb(141, 151, 199)'}}
                            ></div>
                            <div className="font-semibold text-base">Uzun Metraj - Canlı Yayın</div>
                        </div>

                        <div
                            className="bg-gray-100 w-auto lg:w-[250px] scale-75 sm:scale-100 pl-3 pr-2 ml-5 h-20 gap-3 flex-shrink-0 flex justify-start items-center rounded-xl border border-gray-200 cursor-pointer transition-transform duration-500 hover:bg-white hover:-translate-y-3"
                        >
                            <div
                                className="rounded-full w-13 h-13 flex-shrink-0"
                                style={{backgroundColor: 'rgb(173, 141, 199)'}}
                            ></div>
                            <div className="font-semibold text-base">Instant Marketing</div>
                        </div>
                    </div>

                    <div className="mt-24 sm:mt-0" style={{ width: '640px', marginInline: 'auto' }}>
                        <div className="cursor-pointer">
                            <div
                                className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                <div className="font-semibold text-lg">Onedio Tanıtım Videosu</div>
                                <img
                                    src="https://srv-cdn.onedio.com/store/87f51725cb592153e33b5a1f71752f8cc38a56e662f3f59a18be48e6af5e860b.png"
                                    className="h-auto sm:h-full"/></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DigitalCarousel3;
