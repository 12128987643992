import React, {useContext} from 'react';
import {Link} from "react-router-dom";
import img from "../../../assets/icons/instagram.png";
import logo from "../../../assets/icons/logo.png";
import tvIcon from "../../../assets/icons/tv-icon.png";
import fl from "../../../assets/flags/TR.png";
import fl1 from "../../../assets/flags/EN.png";
import Button from "@mui/material/Button";
import {AppContext} from "../../../context/AppContext";

function GeneralWebHeader(props) {
    const {logOut, setLanguage, lang} = useContext(AppContext);
    const userToken = localStorage.getItem('user-token');
    const username = localStorage.getItem('username');

    const activeLanguage = (lan) => {
        if (localStorage.getItem('language') === lan) {
            return {
                width: '24px',
                height: '24px'
            }
        }
    }

    return (
        <div className="const-width web-header-section">
            <div className="logo-container">
                <Link to={'/'} className="site-logo-text">
                    <img className={'logo'} src={logo} alt="user"/>
                </Link>

                <div className='flags-for-language-section'>
                    <img style={activeLanguage('tr')} onClick={() => setLanguage('tr')}
                         className='language-flags' src={fl} alt="Türkiye"/>
                    <img style={activeLanguage('en')} onClick={() => setLanguage('en')}
                         className='language-flags' src={fl1} alt="England"/>
                </div>

                {
                    userToken ?
                        <div className={'exit-button-container'}>
                            <div className={'user-name-container'}>
                                <Link className={'username-text-web'}
                                      to={`/account-detail/${username}`}>{username}</Link>
                            </div>
                            <Button variant="outlined" onClick={logOut}>
                                {
                                    lang === 'tr' ?
                                        <span>Çıkış</span>
                                        :
                                        <span>Logout</span>
                                }
                            </Button>
                        </div>
                        :
                        <div className={'sign-in-sign-up-button-section'}>
                            <div className={'sign-in-button-container'}>
                                {
                                    lang === 'tr' ?
                                        <Link to={'sign-in'} className={'sign-in-text'}>Giriş Yap</Link>
                                        :
                                        <Link to={'sign-in'} className={'sign-in-text'}>SIGN IN</Link>
                                }
                            </div>

                            <div className={'sign-up-button-container'}>
                                {
                                    lang === 'tr' ?
                                        <Link to={'sign-up'} className={'sign-up-text'}>Kayıt Ol</Link>
                                        :
                                        <Link to={'sign-up'} className={'sign-up-text'}>SIGN UP</Link>
                                }
                            </div>
                        </div>
                }
            </div>
        </div>
    );
}

export default GeneralWebHeader;
