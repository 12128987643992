import React from 'react';
import {Link} from "react-router-dom";
import img from "../../../assets/icons/instagram.png";
import logo from "../../../assets/icons/logo.png";
import tvIcon from "../../../assets/icons/tv-icon.png";
import fl from "../../../assets/flags/TR.png";
import fl1 from "../../../assets/flags/EN.png";
import Button from "@mui/material/Button";
import MobileHamburgerMenu from "../mobile-hamburger-menu/MobileHamburgerMenu";

function GeneralMobileHeader(props) {
    return (
        <div className="const-width mobile-header-section">
            <MobileHamburgerMenu />

            <div className="logo-container">
                <Link to={'/'} className="site-logo-text">
                    <img className={'logo'} src={logo} alt="user"/>
                </Link>
            </div>
        </div>
    );
}

export default GeneralMobileHeader;
