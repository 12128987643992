import React from 'react';

function DigitalCarousel6(props) {
    return (
        <div className="w-full relative bg-[#f7f7f7]" id="display-programatik">
            <div
                className="max-w-full sm:max-w-[1440px] mx-auto my-8 pb-24 flex flex-col items-center justify-center gap-12 relative">
                <h2 className="h-20 relative text-[42px] text-center font-semibold mt-5">Data &amp; Display &amp; Programatik<img
                    src="https://srv-cdn.onedio.com/store/1b0abd9841cb6afa1be5884d8c5b6590341bdf4dc5d67dc7d59714245fcea8bc.png"
                    className="absolute -bottom-3 -right-5 sm:-right-12 w-40 h-11 scale-75 sm:scale-100"/></h2><p
                className="text-[#697883] text-base w-full xl:w-[950px] text-center"><span style={{ fontWeight: 600 }}>Preferred Deal(PD)</span>
                <br/>Display yayınlar için Ad Manager zerinden diğer DSP’lerle deal yapılabiliyor. Outstream için ise
                    Teads platformu zerinden diğer DSP’lerle deal yapılabiliyor. PD için DSP platformuyla SSP
                    platformunun aynı olması şartı bulunmuyor.<br/> <br/><span style={{ fontWeight: 600 }}>Programmatic Guaranteed(PG)</span>
                        <br/> PG dealları ile Publisher-Served Custom Creativeler ile programatik projeler
                            gerçekleştirebiliyoruz. PG dealı yapılabilmesi için mşteri tarafında kullanılan DSP
                            platformlarının DV360 gibi Google platformu olması gerekmektedir</p>
                <div className="flex flex-col mx-auto gap-12 max-w-full sm:max-w-[1440px]">
                    <div
                        className="flex justify-start sm:justify-center items-start left-0 gap-1 sm:gap-6 h-20 absolute sm:relative sm:h-32 max-w-full sm:max-w-[1440px] overflow-x-auto sm:overflow-visible">
                        <div
                            className="bg-gray-100 w-auto lg:w-[250px] scale-75 sm:scale-100 pl-3 pr-2 ml-5 h-20 gap-3 flex-shrink-0 flex justify-start items-center rounded-xl border border-gray-200 cursor-pointer transition-transform duration-500 hover:bg-white hover:-translate-y-3 bg-white box-shadow">
                            <div className="rounded-full w-13 h-13 flex-shrink-0"
                                 style={{ backgroundColor: 'rgb(255, 210, 166)' }}></div>
                            <div className="font-semibold text-base">Programatik</div>
                        </div>
                        <div
                            className="bg-gray-100 w-auto lg:w-[250px] scale-75 sm:scale-100 pl-3 pr-2 ml-5 h-20 gap-3 flex-shrink-0 flex justify-start items-center rounded-xl border border-gray-200 cursor-pointer transition-transform duration-500 hover:bg-white hover:-translate-y-3 false">
                            <div className="rounded-full w-13 h-13 flex-shrink-0"
                                 style={{ backgroundColor: 'rgb(255, 183, 183)' }}></div>
                            <div className="font-semibold text-base">Data</div>
                        </div>
                        <div
                            className="bg-gray-100 w-auto lg:w-[250px] scale-75 sm:scale-100 pl-3 pr-2 ml-5 h-20 gap-3 flex-shrink-0 flex justify-start items-center rounded-xl border border-gray-200 cursor-pointer transition-transform duration-500 hover:bg-white hover:-translate-y-3 false">
                            <div className="rounded-full w-13 h-13 flex-shrink-0"
                                 style={{ backgroundColor: 'rgb(195, 238, 223)' }}></div>
                            <div className="font-semibold text-base">Display Alanlar</div>
                        </div>
                        <div
                            className="bg-gray-100 w-auto lg:w-[250px] scale-75 sm:scale-100 pl-3 pr-2 ml-5 h-20 gap-3 flex-shrink-0 flex justify-start items-center rounded-xl border border-gray-200 cursor-pointer transition-transform duration-500 hover:bg-white hover:-translate-y-3 false">
                            <div className="rounded-full w-13 h-13 flex-shrink-0"
                                 style={{ backgroundColor: 'rgb(214, 221, 252)' }}></div>
                            <div className="font-semibold text-base">Social Media Distribution</div>
                        </div>
                    </div>
                    <div className="mt-24 sm:mt-0" style={{ width: '480px', marginInline: 'auto' }}>
                        <div className="cursor-pointer">
                            <div
                                className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                <div className="font-semibold text-lg">Programatik Proje Kataloğu</div>
                                <img
                                    src="https://srv-cdn.onedio.com/store/60d9c3a968133502e0f34b8db73816b2654b46e06a1f0efc08b968759e8cd99e.png"
                                    className="h-auto sm:h-full"/></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DigitalCarousel6;
