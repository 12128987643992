import React, {useContext} from 'react';
import {slide as Menu} from "react-burger-menu";
import img from "../../../assets/icons/instagram.png";
import logo from "../../../assets/icons/logo.png";
import Button from "@mui/material/Button";
import {AppContext} from "../../../context/AppContext";
import fl from "../../../assets/flags/TR.png";
import fl1 from "../../../assets/flags/EN.png";
import tvIcon from "../../../assets/icons/tv-icon.png";
import {Link} from "react-router-dom";

function MobileHamburgerMenu(props) {
    const {
        logOut,
        setLanguage,
        lang
    } = useContext(AppContext);

    const userToken = localStorage.getItem('user-token');
    const username = localStorage.getItem('username');

    const activeLanguage = (lan) => {
        if (localStorage.getItem('language') === lan) {
            return {
                width: '24px',
                height: '24px'
            }
        }
    }

    return (
        <div className={'hamburger-menu-section'}>
            <Menu>
                <a href={'/'} className="burger-menu-site-logo-text">
                    <img className={'logo'} src={logo} alt="user"/>
                </a>
                <div className='authentication-section'>
                    {
                        userToken ?
                            <div className={'user-info-container'}>
                                <div className={'user-detail-container burger-menu-content'}>
                                    <img className={'user-icon'} src={username} alt="user"/>
                                    <div className={'username-text'}>{username}</div>
                                </div>
                                <div className={'mobile-exit-button-container burger-menu-content'}
                                     style={{alignItems: 'self-start'}}>
                                    <Button variant="text" onClick={logOut}><span
                                        className={'mobile-exit-button'}>
                                                        {
                                                            lang === 'tr' ?
                                                                <span>Çıkış</span>
                                                                :
                                                                <span>Logout</span>
                                                        }
                                                    </span></Button>
                                </div>
                            </div>
                            :
                            <div className={'mobile-auth-burger-menu-container'}>
                                <Link to={'/tv'} className="burger-menu-item burger-menu-content">TV GUIDE</Link>
                                <Link id="home" className="burger-menu-item burger-menu-content" to={'/sign-in'}>SIGN IN</Link>
                                <Link id="about" className="burger-menu-item burger-menu-content" to={'/sign-up'}>SIGN UP</Link>
                            </div>
                    }
                </div>

                <div className='flags-for-language-section-mobile'>
                    <img style={activeLanguage('tr')} onClick={() => setLanguage('tr')}
                         className='language-flags-mobile' src={fl} alt="Türkiye"/>
                    <img style={activeLanguage('en')} onClick={() => setLanguage('en')}
                         className='language-flags-mobile' src={fl1} alt="England"/>
                </div>
            </Menu>
        </div>
    );
}

export default MobileHamburgerMenu;
