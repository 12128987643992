import React from 'react';
import '../Digital.css';

function DigitalCarousel1(props) {
    return (
        <div className="w-full relative bg-[#FBFBFB]" id="icerikler">
            <div
                className="max-w-full sm:max-w-[1440px] mx-auto my-8 pb-24 flex flex-col items-center justify-center gap-12 relative">
                <div
                    className="w-20 h-20 sm:w-36 sm:h-36 absolute right-5 -top-16 sm:-top-24 rounded-full bg-[#FFE3E5]"></div>
                <h2 className="h-20 relative text-[42px] text-center font-semibold mt-5">İçerikler<img
                    src="https://srv-cdn.onedio.com/store/1b0abd9841cb6afa1be5884d8c5b6590341bdf4dc5d67dc7d59714245fcea8bc.png"
                    className="absolute -bottom-3 -right-5 sm:-right-12 w-40 h-11 scale-75 sm:scale-100"/></h2><p
                className="text-[#697883] text-base w-full xl:w-[950px] text-center">Markanın hedef kitlesine göre
                liste ve quiz formatında hazırlanan, kullanıcıya olumlu bir Onedio içeriği tecrübesi yaşatırken bir
                yandan native olarak marka ile tüketiciyi buluşturan içerik modelidir.</p>
                <div className="flex flex-col mx-auto gap-12 max-w-full sm:max-w-[1440px]">
                    <div
                        className="flex justify-start sm:justify-center items-start left-0 gap-1 sm:gap-6 h-20 absolute sm:relative sm:h-32 max-w-full sm:max-w-[1440px] overflow-x-auto sm:overflow-visible">
                        <div
                            className="bg-gray-100 w-auto lg:w-[250px] scale-75 sm:scale-100 pl-3 pr-2 ml-5 h-20 gap-3 flex-shrink-0 flex justify-start items-center rounded-xl border border-gray-200 cursor-pointer transition-transform duration-500 hover:bg-white hover:-translate-y-3 bg-white box-shadow">
                            <div className="rounded-full w-13 h-13 flex-shrink-0"
                                 style={{ backgroundColor: 'rgb(255, 210, 166)' }}></div>
                            <div className="font-semibold text-base">Orijinal İçerikler</div>
                        </div>
                        <div
                            className="bg-gray-100 w-auto lg:w-[250px] scale-75 sm:scale-100 pl-3 pr-2 ml-5 h-20 gap-3 flex-shrink-0 flex justify-start items-center rounded-xl border border-gray-200 cursor-pointer transition-transform duration-500 hover:bg-white hover:-translate-y-3 false">
                            <div className="rounded-full w-13 h-13 flex-shrink-0"
                                 style={{ backgroundColor: 'rgb(255, 183, 183)' }}></div>
                            <div className="font-semibold text-base">Liste İçeriği</div>
                        </div>
                        <div
                            className="bg-gray-100 w-auto lg:w-[250px] scale-75 sm:scale-100 pl-3 pr-2 ml-5 h-20 gap-3 flex-shrink-0 flex justify-start items-center rounded-xl border border-gray-200 cursor-pointer transition-transform duration-500 hover:bg-white hover:-translate-y-3 false">
                            <div className="rounded-full w-13 h-13 flex-shrink-0"
                                 style={{ backgroundColor: 'rgb(195, 238, 223)' }}></div>
                            <div className="font-semibold text-base">Quiz / Test İçeriği</div>
                        </div>
                    </div>
                    <div className="mt-24 sm:mt-0" style={{ width: '100%', marginInline: 'auto' }}>
                        <div className="swiper-container swiper-container-initialized swiper-container-horizontal">
                            <div className="swiper-button-prev"></div>
                            <div className="swiper-button-next"></div>
                            <div
                                className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets">
                                    <span
                                        className="swiper-pagination-bullet swiper-pagination-bullet-active"></span><span
                                className="swiper-pagination-bullet"></span></div>
                            <div className="swiper-wrapper"
                                 style={{ transitionDuration: '0ms', transform: 'translate3d(-2900px, 0px, 0px)' }}>
                                <div className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
                                     data-index="0" data-swiper-slide-index="0"
                                     style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg">Sürdürülebilir İçerik Stratejisi
                                        </div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/d84225a8b56827bb5057ef62f92389ce1878f9487a2ce2f353b5eef648afa9af.png"
                                            className="h-auto sm:h-full"/>
                                        <div
                                            className="absolute -right-3 sm:-right-6 -bottom-8 scale-75 sm:scale-100 sm:-bottom-16 bg-[#fb968f] min-w-44 pl-4 pr-16 w-auto h-12 text-white text-md flex justify-start items-center rounded-3xl">Kategori
                                            <div
                                                className="absolute -right-4 top-1/2 transform -translate-y-1/2 bg-[#fb968f] w-16 h-16 rounded-full flex justify-center items-center">
                                                <img
                                                    src="https://srv-cdn.onedio.com/store/c012124bfed751134089144ecb8cccb22d318d05a796da5e152634a569ebc31b.png"
                                                    className="w-4 h-4" loading="lazy"/></div>
                                        </div></div>
                                </div>
                                <div
                                    className="swiper-slide swiper-slide-duplicate swiper-slide-prev swiper-slide-duplicate-next"
                                    data-index="1" data-swiper-slide-index="1"
                                    style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg">Datalı Content Çalışması</div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/e97c6f79d7bf6db24bd895fb4b955dfb113fa1e0e560f5865234bae1d3892dc9.png"
                                            className="h-auto sm:h-full"/>
                                        <div
                                            className="absolute -right-3 sm:-right-6 -bottom-8 scale-75 sm:scale-100 sm:-bottom-16 bg-[#fb968f] min-w-44 pl-4 pr-16 w-auto h-12 text-white text-md flex justify-start items-center rounded-3xl">Rtdu
                                            <div
                                                className="absolute -right-4 top-1/2 transform -translate-y-1/2 bg-[#fb968f] w-16 h-16 rounded-full flex justify-center items-center">
                                                <img
                                                    src="https://srv-cdn.onedio.com/store/c012124bfed751134089144ecb8cccb22d318d05a796da5e152634a569ebc31b.png"
                                                    className="w-4 h-4" loading="lazy"/></div>
                                        </div></div>
                                </div>
                                <div className="swiper-slide swiper-slide-active" data-index="0"
                                     data-swiper-slide-index="0" style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg">Sürdürülebilir İçerik Stratejisi
                                        </div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/d84225a8b56827bb5057ef62f92389ce1878f9487a2ce2f353b5eef648afa9af.png"
                                            className="h-auto sm:h-full"/>
                                        <div
                                            className="absolute -right-3 sm:-right-6 -bottom-8 scale-75 sm:scale-100 sm:-bottom-16 bg-[#fb968f] min-w-44 pl-4 pr-16 w-auto h-12 text-white text-md flex justify-start items-center rounded-3xl">Kategori
                                            <div
                                                className="absolute -right-4 transform -translate-y-1/2 bg-[#fb968f] w-16 h-16 rounded-full flex justify-center items-center">
                                                <img
                                                    src="https://srv-cdn.onedio.com/store/c012124bfed751134089144ecb8cccb22d318d05a796da5e152634a569ebc31b.png"
                                                    className="w-4 h-4" loading="lazy"/></div>
                                        </div></div>
                                </div>
                                <div className="swiper-slide swiper-slide-next swiper-slide-duplicate-prev"
                                     data-index="1" data-swiper-slide-index="1"
                                     style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg">Datalı Content Çalışması</div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/e97c6f79d7bf6db24bd895fb4b955dfb113fa1e0e560f5865234bae1d3892dc9.png"
                                            className="h-auto sm:h-full"/>
                                        <div
                                            className="absolute -right-3 sm:-right-6 -bottom-8 scale-75 sm:scale-100 sm:-bottom-16 bg-[#fb968f] min-w-44 pl-4 pr-16 w-auto h-12 text-white text-md flex justify-start items-center rounded-3xl">Rtdu
                                            <div
                                                className="absolute -right-4 top-1/2 transform -translate-y-1/2 bg-[#fb968f] w-16 h-16 rounded-full flex justify-center items-center">
                                                <img
                                                    src="https://srv-cdn.onedio.com/store/c012124bfed751134089144ecb8cccb22d318d05a796da5e152634a569ebc31b.png"
                                                    className="w-4 h-4" loading="lazy"/></div>
                                        </div></div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
                                     data-index="0" data-swiper-slide-index="0"
                                     style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg">Sürdürülebilir İçerik Stratejisi
                                        </div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/d84225a8b56827bb5057ef62f92389ce1878f9487a2ce2f353b5eef648afa9af.png"
                                            className="h-auto sm:h-full"/>
                                        <div
                                            className="absolute -right-3 sm:-right-6 -bottom-8 scale-75 sm:scale-100 sm:-bottom-16 bg-[#fb968f] min-w-44 pl-4 pr-16 w-auto h-12 text-white text-md flex justify-start items-center rounded-3xl">Kategori
                                            <div
                                                className="absolute -right-4 top-1/2 transform -translate-y-1/2 bg-[#fb968f] w-16 h-16 rounded-full flex justify-center items-center">
                                                <img
                                                    src="https://srv-cdn.onedio.com/store/c012124bfed751134089144ecb8cccb22d318d05a796da5e152634a569ebc31b.png"
                                                    className="w-4 h-4" loading="lazy"/></div>
                                        </div></div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next"
                                     data-index="1" data-swiper-slide-index="1"
                                     style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg">Datalı Content Çalışması</div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/e97c6f79d7bf6db24bd895fb4b955dfb113fa1e0e560f5865234bae1d3892dc9.png"
                                            className="h-auto sm:h-full"/>
                                        <div
                                            className="absolute -right-3 sm:-right-6 -bottom-8 scale-75 sm:scale-100 sm:-bottom-16 bg-[#fb968f] min-w-44 pl-4 pr-16 w-auto h-12 text-white text-md flex justify-start items-center rounded-3xl">Rtdu
                                            <div
                                                className="absolute -right-4 top-1/2 transform -translate-y-1/2 bg-[#fb968f] w-16 h-16 rounded-full flex justify-center items-center">
                                                <img
                                                    src="https://srv-cdn.onedio.com/store/c012124bfed751134089144ecb8cccb22d318d05a796da5e152634a569ebc31b.png"
                                                    className="w-4 h-4" loading="lazy"/></div>
                                        </div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DigitalCarousel1;
