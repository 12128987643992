import React from 'react';

function DigitalFooter(props) {
    return (
        <div
            className="bg-[#F0F2EE] w-full max-w-[1440px] rounded-t-3xl p-12 mx-auto mt-20 flex flex-col gap-6 items-center">
            <div className="flex gap-5">
                <div
                    className="w-10 h-10 sm:w-12 sm:h-12 bg-white flex justify-center items-center rounded-full cursor-pointer hover:bg-[#FD9790] hover:scale-110">
                    <img src="https://proj-assets.onedio.com/sunum/facebook.svg" alt="Facebook" loading="lazy"/></div>
                <div
                    className="w-10 h-10 sm:w-12 sm:h-12 bg-white flex justify-center items-center rounded-full cursor-pointer hover:bg-[#FD9790] hover:scale-110">
                    <img src="https://proj-assets.onedio.com/sunum/instagram.svg" alt="Instagram" loading="lazy"/></div>
                <div
                    className="w-10 h-10 sm:w-12 sm:h-12 bg-white flex justify-center items-center rounded-full cursor-pointer hover:bg-[#FD9790] hover:scale-110">
                    <img src="https://proj-assets.onedio.com/sunum/twitter-x.svg" alt="X" loading="lazy"/></div>
                <div
                    className="w-10 h-10 sm:w-12 sm:h-12 bg-white flex justify-center items-center rounded-full cursor-pointer hover:bg-[#FD9790] hover:scale-110">
                    <img src="https://proj-assets.onedio.com/sunum/tiktok.svg" alt="Tiktok" loading="lazy"/></div>
                <div
                    className="w-10 h-10 sm:w-12 sm:h-12 bg-white flex justify-center items-center rounded-full cursor-pointer hover:bg-[#FD9790] hover:scale-110">
                    <img src="https://proj-assets.onedio.com/sunum/youtube.svg" alt="Youtube" loading="lazy"/></div>
                <div
                    className="w-10 h-10 sm:w-12 sm:h-12 bg-white flex justify-center items-center rounded-full cursor-pointer hover:bg-[#FD9790] hover:scale-110">
                    <img src="https://proj-assets.onedio.com/sunum/linkedin.svg" alt="Linkedin" loading="lazy"/></div>
            </div>
            <h2 className="block text-2xl sm:text-4xl mx-auto font-semibold text-center">Birçok farklı ürün ve doğru
                stratejilerle<br/>ihtiyaçlarınıza çözüm sunabilmemiz için,</h2><p
            className="block text-md sm:text-xl mx-auto text-center"><a href="mailto:sales@onedio.com"
                                                                        className="text-blue-300">sales@onedio.com</a> adresinden
            bizlere ulaşabilirsiniz.<br/>Ya da mail adresinizi aşağıya bırakın, biz size ulaşalım 🤣</p>
            <div className="flex gap-5"><input type="text"
                                               className="rounded-lg bg-white h-11 w-60 sm:w-80 box-shadow pl-5 outline-none"/>
                <div className="z-50 undefined">
                    <button
                        className="flex h-11 justify-center relative items-center text-base text-white bg-[#FD9790] px-8 rounded-lg border-[#FD9790] transition duration-500 hover:scale-105">Gönder
                    </button>
                </div></div>
        </div>
);
}

export default DigitalFooter;
