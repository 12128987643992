import React from 'react';

function DigitalCarousel4(props) {
    return (
        <div className="w-full relative bg-[#fdf2f0]" id="yemekler">
            <div
                className="max-w-full sm:max-w-[1440px] mx-auto my-8 pb-24 flex flex-col items-center justify-center gap-12 relative">
                <h2 className="h-20 relative text-[42px] text-center font-semibold mt-5">Yemekler<img
                    src="https://srv-cdn.onedio.com/store/1b0abd9841cb6afa1be5884d8c5b6590341bdf4dc5d67dc7d59714245fcea8bc.png"
                    className="absolute -bottom-3 -right-5 sm:-right-12 w-40 h-11 scale-75 sm:scale-100"/></h2><p
                className="text-[#697883] text-base w-full xl:w-[950px] text-center">Onedio Yemek kategorisinde, yemeğin
                her alanına dair hem yazılı içerikler, hem de Onedio Yemek Youtube ve Instagram sayfalarında video
                içerikler ile marka ve ürüne özel videolar hazırlayarak, genel kabul görmüş yemek video hedef kitlesine
                alternatif olarak tüm hedef kitleler özelinde milyonlarca kullanıcıyla buluşuyoruz.</p>
                <div className="flex flex-col mx-auto gap-12 max-w-full sm:max-w-[1440px]">
                    <div
                        className="flex justify-start sm:justify-center items-start left-0 gap-1 sm:gap-6 h-20 absolute sm:relative sm:h-32 max-w-full sm:max-w-[1440px] overflow-x-auto sm:overflow-visible">
                        <div
                            className="bg-gray-100 w-auto lg:w-[250px] scale-75 sm:scale-100 pl-3 pr-2 ml-5 h-20 gap-3 flex-shrink-0 flex justify-start items-center rounded-xl border border-gray-200 cursor-pointer transition-transform duration-500 hover:bg-white hover:-translate-y-3 bg-white box-shadow">
                            <div className="rounded-full w-13 h-13 flex-shrink-0"
                                 style={{ backgroundColor: 'rgb(255, 210, 166)' }}></div>
                            <div className="font-semibold text-base">Newgen</div>
                        </div>
                        <div
                            className="bg-gray-100 w-auto lg:w-[250px] scale-75 sm:scale-100 pl-3 pr-2 ml-5 h-20 gap-3 flex-shrink-0 flex justify-start items-center rounded-xl border border-gray-200 cursor-pointer transition-transform duration-500 hover:bg-white hover:-translate-y-3 false">
                            <div className="rounded-full w-13 h-13 flex-shrink-0"
                                 style={{ backgroundColor: 'rgb(255, 183, 183)' }}></div>
                            <div className="font-semibold text-base">Video Tarifleri</div>
                        </div>
                        <div
                            className="bg-gray-100 w-auto lg:w-[250px] scale-75 sm:scale-100 pl-3 pr-2 ml-5 h-20 gap-3 flex-shrink-0 flex justify-start items-center rounded-xl border border-gray-200 cursor-pointer transition-transform duration-500 hover:bg-white hover:-translate-y-3 false">
                            <div className="rounded-full w-13 h-13 flex-shrink-0"
                                 style={{ backgroundColor: 'rgb(195, 238, 223)' }}></div>
                            <div className="font-semibold text-base">Fon Format Video</div>
                        </div>
                    </div>
                    <div className="mt-24 sm:mt-0" style={{ width: '100%', marginInline: 'auto' }}>
                        <div className="swiper-container swiper-container-initialized swiper-container-horizontal">
                            <div className="swiper-button-prev"></div>
                            <div className="swiper-button-next"></div>
                            <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets">
                                <span className="swiper-pagination-bullet swiper-pagination-bullet-active"></span><span
                                className="swiper-pagination-bullet"></span></div>
                            <div className="swiper-wrapper"
                                 style={{ transitionDuration: '0ms', transform: 'translate3d(-2900px, 0px, 0px)' }}>
                                <div className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
                                     data-index="0" data-swiper-slide-index="0"
                                     style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg">PUBG'de galibiyet aldıktan sonra ben 🍗
                                        </div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/8183d6e6ce21561b71b0aab545a3eb28819313732a9b0931b83bb0aa4e65d8e3.png"
                                            className="h-auto sm:h-full"/></div>
                                </div>
                                <div
                                    className="swiper-slide swiper-slide-duplicate swiper-slide-prev swiper-slide-duplicate-next"
                                    data-index="1" data-swiper-slide-index="1"
                                    style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg">Makarna Cipsi</div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/53a40deafa98aaa3a9ea0e2141bb09e2549f41435f50666e460b8db0f5642bb8.png"
                                            className="h-auto sm:h-full"/></div>
                                </div>
                                <div className="swiper-slide swiper-slide-active" data-index="0"
                                     data-swiper-slide-index="0" style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg">PUBG'de galibiyet aldıktan sonra ben 🍗
                                        </div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/8183d6e6ce21561b71b0aab545a3eb28819313732a9b0931b83bb0aa4e65d8e3.png"
                                            className="h-auto sm:h-full"/></div>
                                </div>
                                <div className="swiper-slide swiper-slide-next swiper-slide-duplicate-prev"
                                     data-index="1" data-swiper-slide-index="1"
                                     style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg">Makarna Cipsi</div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/53a40deafa98aaa3a9ea0e2141bb09e2549f41435f50666e460b8db0f5642bb8.png"
                                            className="h-auto sm:h-full"/></div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
                                     data-index="0" data-swiper-slide-index="0"
                                     style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg">PUBG'de galibiyet aldıktan sonra ben 🍗
                                        </div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/8183d6e6ce21561b71b0aab545a3eb28819313732a9b0931b83bb0aa4e65d8e3.png"
                                            className="h-auto sm:h-full"/></div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next"
                                     data-index="1" data-swiper-slide-index="1"
                                     style={{ width: '1440px', marginRight: '10px' }}>
                                    <div
                                        className="flex flex-col items-center mx-auto gap-4 relative w-[90%] sm:w-[480px] sm:h-[290px]">
                                        <div className="font-semibold text-lg">Makarna Cipsi</div>
                                        <img
                                            src="https://srv-cdn.onedio.com/store/53a40deafa98aaa3a9ea0e2141bb09e2549f41435f50666e460b8db0f5642bb8.png"
                                            className="h-auto sm:h-full"/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DigitalCarousel4;
